import axios from "axios";

const url = process.env.REACT_APP_HOST;

const httpClient = axios.create({
    baseURL: url
});

httpClient.interceptors.request.use(async config=>{
    return config;
});


httpClient.interceptors.response.use(response=>{
    return response;
})

export default httpClient
