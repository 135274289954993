import {BackendRoutes} from "../../models/Routers";
import httpClient from "../../cfgs/httpClientCfg";
import {DownloadBoleto, PaginatorBoletoSearch, SearchBoleto} from "../../models/boletoModels";
import {ResponseBackend} from "../../models/BackendDefaults";


export class BoletoService{
    getBoletoList(param: SearchBoleto, page: number){
        let url = `${BackendRoutes.GET_BOLETOS}/${page}`
        return httpClient.post<ResponseBackend<PaginatorBoletoSearch, undefined>>(url, param)
    }
    donwloadBoleto(param: DownloadBoleto){
        let url = `${BackendRoutes.DOWNLOAD_BOLETO}`
        return httpClient.post<Blob>(url, param, {responseType: 'blob'});
    }
}